import React from "react"
import Layout from "../components/layout"
import RequestQuoteForm from "../components/requestQuoteForm"

const RequestQuote = ({ location }) => {
  return (
    <Layout>
      <section className="hero is-medium has-bg-img">
        <div className="hero-body">
          <div className="container">
            <div className="has-text-centered">
              <span className="is-size-1 has-text-white has-text-weight-bold">
                Request A Quote
              </span>
              <p className="is-size-4 has-text-white mb-1">
                Start your project today.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="contianer pt-5 px-5">
          <div className="columns mt-5">
            <div className="column px-5 py-5">
              <div className="mb-3">
                <p className="is-size-4 mb-2 has-text-weight-bold">
                  What is included in mailing services?
                </p>
                <p>
                  Address cleansing and standardization of one mailing list,
                  NCOA, pre-sorting, ink-jet set up and addressing, as well as
                  post office delivery
                </p>
              </div>
              <hr />
              <div className="mb-3">
                <p className="is-size-4 mb-2 has-text-weight-bold">
                  Should I send my mail Standard or First Class?
                </p>
                <p>
                  How you choose to send your mail depends on your delivery
                  needs and your postal budget. Postal rates for “Standard” mail
                  are cheaper, but delivery can take up to two weeks longer.
                  “First-Class” is typically used for customized and
                  personalized mail, such as statements and notices and
                  recipient-specific mail. “Standard” mail is an excellent and
                  economical choice for advertising pieces.
                </p>
              </div>
              <hr />
              <div className="mb-3">
                <p className="is-size-4 mb-2 has-text-weight-bold">
                  Do I need to have a postal permit to use your services?
                </p>
                <p>
                  No! That’s one of the great advantages of working with us: we
                  handle all relations with the U.S. Postal Service and can send
                  your mail out using our permit.
                </p>
              </div>
              <hr />
              <div className="mb-3">
                <p className="is-size-4 mb-2 has-text-weight-bold">
                  What file type does my mailing list need to be?
                </p>
                <p>We accept .XLS, .CSV, or .TXT file formats</p>
              </div>
            </div>
            <div className="column">
              <RequestQuoteForm location={location.pathname} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RequestQuote
